.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

:root {
  --value: 0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

tr.hidetr td, tr.hidetr td * {
  height: 0;
  padding: 0;
  border-top: 0;
  overflow: hidden;
  line-height: 0;
  transform: translate(-800px, 0);
  transition: all ease 0.5s;
}

.field-container > *:nth-child(2n+2){
  margin-right: 10px;
}

.field-container > *:nth-child(2n+1){
  margin-left: 10px;
}

.rfm-chart .flex.flex-col.justify-start.items-center:hover {
/*  scale by animate*/
    transform: scale(1.1);
  transition: transform 0.5s;
  cursor: pointer;
}
